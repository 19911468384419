import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { ParamatUniverse } from '../../paramatruntime/paramatuniverse';

@Component({
  selector: 'paramat-universe-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})

export class ParamatUniverseEditorComponent implements OnInit, AfterViewInit {
  @Input() universe: ParamatUniverse;


  componentName: string = 'Werkblad AA';
  componentDescription: string = '2000x500x40';

  attributes = [
    {
      label: "Lengte",
      value: 2020,
      type: "number"
    },
    {
      label: "Breedte",
      value: 600,
      type: "number"
    },
    {
      label: "Hoogte",
      value: 40,
      type: "number"
    }
  ];


  origin = {
    x: 0,
    y: 0,
    z: 0
  };
  normal = {
    dx: 0,
    dy: 0, 
    dz: 1
  };
  north = {
    dx: 0,
    dy: -1,
    dz: 0
  };
  

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    console.log(this.universe);
  }

  attributeChanged(component, attribute, event) {
    
    if(attribute.type === 'distance') {
      // make a new string value
      const newValue = '' + event.target.value + 'mm';
    
      console.log('Attribute ' + attribute.name + ' set to ' + newValue);

      attribute.updateValueEvent(newValue);
    } else {
      console.log('!!! AttributeChanged', component, attribute, event);
    }
  }

  /**
   * Some position property has changed
   */
  positionChanged(component, key, value) {
    let keyParts = key.split('.');

    switch(keyParts[0]) {
    case 'normal':
      // @todo should value be a Float?
      component.updateNormalEvent(keyParts[1], parseInt(value));
      break;
    case 'north':
      // @todo should value be a Float?
      component.updateNorthEvent(keyParts[1], parseInt(value));
      break;
    case 'origin':
      // @todo should value be a Float?
      component.updateOriginEvent(keyParts[1], parseInt(value));
      break;
    default:
      throw new Error('Cannot process "' + keyParts[0] + '"');
    }
  }
}
