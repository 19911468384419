import { Injectable } from '@angular/core';
import { 
  AngularFirestore, 
  AngularFirestoreCollection,
  DocumentChangeAction,
  Action,
  DocumentSnapshotDoesNotExist,
  DocumentSnapshotExists,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';

export interface Universe { label: string, id: string }

export interface Drawing { label: string; id: string }
export interface DrawingId extends Drawing { id: string; }

export interface DrawingComplete extends Drawing { universes: Universe[]; }

export interface Documenttemplate { label: string; id: string;  }

export interface ParamatComponent { name: string; path: string; type: string, version: string; configuration: string };

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(private afs: AngularFirestore) { 
  }

  /**
   * Get a single documenttemplate
   */
  getDocumenttemplateById(id: string): Observable<Documenttemplate> {
    let service = this;
    return service.afs.doc<Documenttemplate>('documenttemplates/'+id).valueChanges()
  }

  /**
   * Gets an updated list of documenttemplates
   '*/
  getDocumenttemplates(): Observable<Documenttemplate[]> {
    let service = this;

    const documenttemplates: Observable<Documenttemplate[]> = service.afs.collection<Documenttemplate>('documenttemplates').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Drawing;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
    return documenttemplates;
  }

  /**
   * Get a single drawing
   */
  getDrawingById(id: string): Observable<Drawing> {
    let service = this;
    return service.afs.doc<Drawing>('drawings/'+id).valueChanges()
  }

  /**
   * Get a single drawing and its universes
   */
  getDrawingcompleteById(id: string): Observable<DrawingComplete> {
    let service = this;

    return new Observable<DrawingComplete>(observer => {
      const {next, error} = observer;
      let result : DrawingComplete = { label: 'Yo', universes: new Array(), id: id};
      observer.next(result);

      let drawingObservable = service.afs.doc('drawings/'+id).valueChanges();
      let universesObservable = service.afs.doc('drawings/'+id).collection('universes').valueChanges();

      // observe when drawing document changes
      let drawingObservableSubscription = drawingObservable.subscribe({
        next(drawing: any) {
          result.label = drawing.label;
          observer.next(result);
        }
      });
      // observe when univers documents of the drawing change
      let universesObservableSubscription = universesObservable.subscribe({
        next(universes: any[]) {
          result.universes = new Array();
          universes.forEach(universe => {
            result.universes.push({ label: universe.label, id: universe.id });
          });
          observer.next(result);
        }
      });

      return {unsubscribe() {
        // @todo unsubscribe to the values
        drawingObservableSubscription.unsubscribe();
        universesObservableSubscription.unsubscribe();
      }};
    });
  }

  /**
   * Gets an updated list of drawings
   */
  getDrawings(): Observable<Drawing[]> {
    let service = this;

    const drawings: Observable<Drawing[]> = service.afs.collection<Drawing>('drawings').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Drawing;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
    return drawings;
  }

  /**
   * Create a drawing by its label
   */
  createDrawing(label: string) {
    let service = this;
    return service.afs.collection('drawings').add({ label: label }).then(drawingRef => {
      console.log('Drawing with id "' + drawingRef.id + '" created');
      return drawingRef.id;
    });
  }

  /**
   * Creates a component directly in Firebase
   */
  createComponent(name: string, type: string, version: string, path: string, configuration: string) {
    let service = this;
    let item: ParamatComponent = {
      name: name,
      type: type,
      version: version,
      path: path,
      configuration: configuration
    };

console.log('The component to save: ', item);
    return service.afs.collection<ParamatComponent>('components').add(item);
  }

  /**
   * Gets an updated list of components
   */
  getComponents(): Observable<ParamatComponent[]> {
    let service = this;

    const components: Observable<ParamatComponent[]> = service.afs.collection<ParamatComponent>('components').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as ParamatComponent;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
    return components;
  }

  /**
   * Get a single paramatcomponent
   */
  getParamatcomponentById(id: string): Observable<ParamatComponent> {
    let service = this;
    return service.afs.doc<ParamatComponent>('components/'+id).valueChanges()
  }


}
